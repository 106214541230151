import React, { useCallback, useEffect, useMemo } from "react"
import { Stack, Chip, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { applyFilters, clearFilters } from "../../redux/filters/filters.slice"
import { type RootState } from "../../redux/store"
import { scrollToTop } from "../../utils/scrollToTop"

interface HeaderTagsProps {
  tags: string[]
}

export const HeaderTags: React.FC<HeaderTagsProps> = React.memo(({ tags }: HeaderTagsProps) => {
  const dispatch = useDispatch()
  const newsTagFilters = useSelector((state: RootState) => state.filters.newsTagFilters)

  const selectedTags = useMemo(
    () => tags.filter((tag) => newsTagFilters[tag]),
    [tags, newsTagFilters]
  )

  useEffect(() => {
    scrollToTop()
  }, [selectedTags])

  const handleTagClick = useCallback(
    (tag: string) => {
      dispatch(
        applyFilters({
          filter: "newsTagFilters",
          filterType: tag,
          value: !newsTagFilters[tag],
        })
      )
    },
    [dispatch, newsTagFilters]
  )

  const handleClearAll = useCallback(() => {
    dispatch(clearFilters({ filter: "newsTagFilters" }))
  }, [dispatch])

  if (tags.length === 0) return null

  return (
    <Stack
      direction="row"
      sx={{
        gap: "4px",
        flexWrap: "wrap",
        justifyContent: "center",
        py: 1,
        position: "relative",
      }}
    >
      {tags.map((tag, index) => (
        <Chip
          key={index}
          label={tag}
          variant={selectedTags.includes(tag) ? "filled" : "outlined"}
          sx={{
            borderRadius: "16px",
          }}
          onClick={() => {
            handleTagClick(tag)
          }}
        />
      ))}

      <Button
        size="small"
        onClick={handleClearAll}
        disabled={selectedTags.length === 0}
        sx={{
          color: "black",
          textTransform: "none",
        }}
      >
        show all
      </Button>
    </Stack>
  )
})

HeaderTags.displayName = "HeaderTags"
