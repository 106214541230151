import React, { useCallback } from "react"
import { Stack, Chip } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { applyFilters } from "../../../redux/filters/filters.slice"
import { type RootState } from "../../../redux/store"
import { scrollToTop } from "../../../utils/scrollToTop"

interface NewsCardTagsProps {
  tags: string[]
}

export const NewsCardFooterTags: React.FC<NewsCardTagsProps> = React.memo(
  ({ tags }: NewsCardTagsProps) => {
    const dispatch = useDispatch()
    const newsTagFilters = useSelector((state: RootState) => state.filters.newsTagFilters)

    const handleTagClick = useCallback(
      (tag: string) => {
        if (!newsTagFilters[tag]) {
          dispatch(
            applyFilters({
              filter: "newsTagFilters",
              filterType: tag,
              value: true,
            })
          )
          scrollToTop()
        }
      },
      [dispatch, newsTagFilters]
    )

    return (
      <Stack direction="row" spacing={1}>
        {tags.map((tag, index) => {
          return (
            <Chip
              key={index}
              label={tag}
              variant="filled"
              sx={{
                borderRadius: "13px",
              }}
              onClick={() => {
                handleTagClick(tag)
              }}
            />
          )
        })}
      </Stack>
    )
  }
)
NewsCardFooterTags.displayName = "NewsCardFooterTags"
