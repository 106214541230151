import type { INewsImage } from "../types/INews"

export function imageFormatFinder(
  media: INewsImage,
  thumbnail?: boolean,
  screenSize?: "large" | "medium" | "small"
) {
  // If thumbnail is requested, return the thumbnail if available, else fallback to media.url.
  if (thumbnail === true) {
    if (media?.formats?.thumbnail?.url != null) {
      return media.formats.thumbnail.url
    }
    return media.url
  }

  // If formats are null or undefined, just return the media.url
  if (media?.formats == null) {
    return media.url
  }

  // Use a switch(true) pattern to check conditions in order:
  // 1. Check if requested screenSize is available
  // 2. If not, fallback to largest available format
  // 3. Default to media.url
  switch (true) {
    // Attempt to match the requested screenSize first
    case screenSize === "large" && media.formats.large != null:
      return media.formats.large.url
    case screenSize === "medium" && media.formats.medium != null:
      return media.formats.medium.url
    case screenSize === "small" && media.formats.small != null:
      return media.formats.small.url

    // If the requested size isn't available, fallback to the largest available
    case media.formats.large != null:
      return media.formats.large.url
    case media.formats.medium != null:
      return media.formats.medium.url
    case media.formats.small != null:
      return media.formats.small.url

    // If no format matches, return the original media.url
    default:
      return media.url
  }
}
