import { useState } from "react"
import { NewsCard } from "./NewsCard"
import { LazyLoadWrapper } from "../LazyLoadWrapper"
import { type INewsItem } from "../../types/INews"
import { Skeleton } from "@mui/material"

interface NewsListProps {
  newsData: INewsItem[]
}

const ITEMS_PER_PAGE = 5

export const NewsList: React.FC<NewsListProps> = ({ newsData }: NewsListProps) => {
  const [visibleItems, setVisibleItems] = useState(ITEMS_PER_PAGE)

  const loadMore = () => {
    setVisibleItems((prev) => prev + ITEMS_PER_PAGE)
  }

  return (
    <>
      {newsData.slice(0, visibleItems).map((newsItem: INewsItem) => (
        <NewsCard
          key={newsItem.documentId}
          id={newsItem.id}
          documentId={newsItem.documentId}
          title={newsItem.Title}
          body={newsItem.Body}
          cover={newsItem.Cover}
          gallery={newsItem.Gallery}
          publishedAt={newsItem.publishedAt}
          tag1={newsItem.Tag1}
          tag2={newsItem.Tag2}
          tag3={newsItem.Tag3}
        />
      ))}
      {visibleItems < newsData.length && (
        <LazyLoadWrapper onIntersect={loadMore}>
          <Skeleton />
        </LazyLoadWrapper>
      )}
    </>
  )
}
