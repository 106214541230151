import { CardMedia } from "@mui/material"
import { LazyLoadWrapper } from "../../LazyLoadWrapper"
import { type INewsImage } from "../../../types/INews"

interface NewsCoverProps {
  cover: INewsImage
}

export const NewsCover = ({ cover }: NewsCoverProps) => {
  if (cover == null) return null

  return (
    <LazyLoadWrapper>
      <CardMedia
        component="img"
        image={`${process.env.REACT_APP_STRAPI_URL as string}${cover?.url}`}
        alt={cover.alternativeText ?? ""}
        loading="lazy"
        sx={{
          maxWidth: "100%",
          maxHeight: "auto",
          objectFit: "contain",
          borderRadius: "5px",
          display: "block",
          margin: "0 auto",
        }}
      />
    </LazyLoadWrapper>
  )
}
