// Third-party libraries
import { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Alert } from "@mui/material"

// Types
import { type RootState } from "../../redux/store"

// Redux
import { useGetNewsQuery, useUpdateLastReadMutation } from "../../redux/business/business.api"
import { applyFilters, clearFilters } from "../../redux/filters/filters.slice"

// Components
import { NewsHeader } from "../../components/News/NewsHeader"
import { NewsList } from "../../components/News/NewsList"

// Layouts
import { ReportSkeleton } from "../../layouts/Skeletons/ReportSkeleton"
import { ScrollTop } from "../../components/ScrollTop"

export const News: React.FC = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: apiResponse, isFetching, error } = useGetNewsQuery(null)
  const [updateTimeStamp] = useUpdateLastReadMutation()
  const newsData = apiResponse?.ApiResponse?.data
  const newsTagFilters = useSelector((state: RootState) => state.filters.newsTagFilters)

  // 1) On mount, parse any URL params (e.g. ?tags=One,Two)
  //    and dispatch them to Redux
  useEffect(() => {
    const tagsParam = searchParams.get("tags") // e.g. "tag1,tag2"
    if (tagsParam != null) {
      const parsedTags = tagsParam.split(",")
      // Dispatch to Redux for each tag
      parsedTags.forEach((tag) => {
        dispatch(
          applyFilters({
            filter: "newsTagFilters",
            filterType: tag,
            value: true,
          })
        )
      })
    }
  }, [dispatch, searchParams])

  // 2) Whenever Redux newsTagFilters changes,
  //    update the URL query param to match
  useEffect(() => {
    const activeTags = Object.entries(newsTagFilters)
      .filter(([, isActive]) => isActive)
      .map(([tag]) => tag)

    if (activeTags.length > 0) {
      setSearchParams({ tags: activeTags.join(",") }, { replace: true })
    } else {
      // If no tags selected, remove the `tags` param from the URL
      searchParams.delete("tags")
      setSearchParams(searchParams, { replace: true })
    }
  }, [newsTagFilters, setSearchParams, searchParams])

  // 3) Clear Redux filters on unmount (route change).
  //    This ensures other pages in the app don’t “inherit” leftover filters.
  useEffect(() => {
    return () => {
      dispatch(clearFilters({ filter: "newsTagFilters" }))
    }
  }, [dispatch])

  const sortedNewsData = useMemo(() => {
    if (newsData == null || newsData.length === 0) return []
    return [...newsData].sort((a, b) => {
      return new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
    })
  }, [newsData])

  // Filter news by selected tags
  const filteredNews = useMemo(() => {
    if (sortedNewsData.length === 0) return []
    const activeFilters = Object.entries(newsTagFilters)
      .filter(([, isActive]) => isActive)
      .map(([tag]) => tag)

    if (activeFilters.length === 0) return sortedNewsData

    return sortedNewsData.filter((news) => {
      const newsTags = [news.Tag1, news.Tag2, news.Tag3].filter(Boolean)
      return activeFilters.some((tag) => newsTags.includes(tag))
    })
  }, [sortedNewsData, newsTagFilters])

  // Extracting all unique tags
  const tags = useMemo(() => {
    if (sortedNewsData.length === 0) return []
    return Array.from(
      new Set(
        sortedNewsData.flatMap((newsItem) =>
          [newsItem.Tag1, newsItem.Tag2, newsItem.Tag3].filter(Boolean)
        )
      )
    )
  }, [sortedNewsData])

  useEffect(() => {
    if (!isFetching && error == null) {
      void updateTimeStamp({})
    }
  }, [isFetching])

  return (
    <div className="w-full md:max-w-4xl mx-auto mb-1">
      <NewsHeader title="News" tags={tags} />
      {isFetching && <ReportSkeleton />}
      {!isFetching && error == null && filteredNews.length === 0 && (
        <Alert severity="error" sx={{ width: "100%" }}>
          {"No news articles available at this time."}
        </Alert>
      )}
      {!isFetching && filteredNews.length > 0 && <NewsList newsData={filteredNews} />}
      <ScrollTop />
    </div>
  )
}
