import { useEffect, useRef, useState, type ReactNode } from "react"

interface LazyLoadWrapperProps {
  children: ReactNode
  onIntersect?: () => void
}

export const LazyLoadWrapper = ({ children, onIntersect }: LazyLoadWrapperProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true)
          onIntersect?.()
          observer.disconnect()
        }
      },
      {
        threshold: 0.1,
        rootMargin: "50px",
      }
    )

    if (elementRef.current != null) {
      observer.observe(elementRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [onIntersect])

  return <div ref={elementRef}>{isVisible ? children : null}</div>
}
