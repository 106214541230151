import { Card, CardContent, CardHeader } from "@mui/material"
import { NewsBody } from "./NewsCard/NewsBody"
import { NewsFooter } from "./NewsCard/NewsFooter"
import { NewsGallery } from "./NewsCard/NewsGallery"
import { NewsCover } from "./NewsCard/NewsCover"
import { NewsDate } from "./NewsCard/NewsDate"
import type { IGetLatest, INewsCardItem } from "../../types/INews"

const fontFamily =
  '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

export const NewsCard: React.FC<INewsCardItem> = ({
  title,
  body,
  cover,
  gallery,
  publishedAt,
  tag1,
  tag2,
  tag3,
  id,
  documentId,
}: INewsCardItem) => {
  const getLatest: IGetLatest = JSON.parse(localStorage.getItem("getLatest") ?? "{}")
  const showBadge = getLatest?.hasNew && getLatest?.documentIds.includes(documentId.toString())
  return (
    <Card
      sx={{
        marginY: 2,
        fontFamily,
        position: "relative",
        "@keyframes pulse": {
          "0%": {
            transform: "scale(1)",
            opacity: 0.8,
          },

          "100%": {
            transform: "scale(1.5)",
            opacity: 0,
          },
        },
        "&::before": showBadge
          ? {
              content: '""',
              position: "absolute",
              top: 4,
              left: 4,
              width: 0,
              height: 0,
              borderRadius: "4px 4px 4px 4px",
              borderLeft: "20px solid #d32f2f",
              borderBottom: "20px solid transparent",
              zIndex: 2,
            }
          : {},
        "&::after": showBadge
          ? {
              content: '""',
              position: "absolute",
              top: 4,
              left: 4,
              width: 0,
              height: 0,
              borderRadius: "4px 4px 4px 4px",
              borderLeft: "20px solid #d32f2f",
              borderBottom: "20px solid transparent",
              zIndex: 1,
              animation: "pulse 2s infinite",
            }
          : {},
      }}
      key={id}
    >
      <CardHeader
        title={title}
        sx={{
          "& .MuiCardHeader-title": {
            fontFamily,
          },
        }}
      />
      <CardContent>
        <NewsCover cover={cover} />
        <NewsBody body={body} />
        <NewsGallery media={gallery} />
        <NewsDate date={publishedAt} />
      </CardContent>
      <NewsFooter tag1={tag1} tag2={tag2} tag3={tag3} />
    </Card>
  )
}
