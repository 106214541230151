import { Paper } from "@mui/material"
import { HeaderTags } from "./HeaderTags"
import { scrollToTop } from "../../utils/scrollToTop"

interface NewsHeaderProps {
  title: string
  tags: string[]
}

export function NewsHeader({ title, tags }: NewsHeaderProps) {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 10,
        boxShadow: "none",
        "&::after": {
          content: '""',
          position: "absolute",
          left: 0,
          right: 0,
          height: "5px",
          background: "linear-gradient(to bottom, rgba(0,0,0,0.08), transparent)",
        },
      }}
    >
      <div className="py-2 bg-[#14213D]">
        <div className="text-center">
          <h1
            className="text-white font-bold text-2xl"
            onClick={() => {
              scrollToTop()
            }}
          >
            {title}
          </h1>
        </div>
      </div>
      <HeaderTags tags={tags} />
    </Paper>
  )
}
