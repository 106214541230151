import React, { useState } from "react"
import { Box, useTheme, useMediaQuery, IconButton, Modal, Grid, Typography } from "@mui/material"
import { LazyLoadWrapper } from "../../LazyLoadWrapper"
import { styled } from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import { type INewsImage } from "../../../types/INews"
import { imageFormatFinder } from "../../../utils/imageFormatFinder"

interface NewsGalleryProps {
  media: INewsImage[]
}

interface MediaContainerProps {
  media: INewsImage
  currentIndex?: number
}

const STRAPI_URL = process.env.REACT_APP_STRAPI_URL as string

const ThumbnailContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
  width: "100%",
  aspectRatio: "16/9",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}))

const ThumbnailImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "center",
  transition: "transform 0.3s ease",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    transform: "scale(1.1)",
  },
}))

const FullscreenModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
  backdropFilter: "blur(4px)",
  // outline: "none",
}))

const ModalNavButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 0,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
}))

const VideoOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
})

const MediaContainer = ({ media }: MediaContainerProps) => {
  const isVideo = media.mime.startsWith("video/")

  if (isVideo) {
    return (
      <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
        <video
          controls
          style={{
            objectFit: "cover",
            borderRadius: "4px",
          }}
        >
          <source src={`${STRAPI_URL}${media.url}`} type={media.mime} />
        </video>
      </Box>
    )
  }
  // Use theme breakpoints and useMediaQuery to determine screenSize
  const theme = useTheme()
  const isLargeUp = useMediaQuery(theme.breakpoints.up("lg"))
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"))

  let screenSize: "large" | "medium" | "small" = "small"

  if (isLargeUp) {
    screenSize = "large"
  } else if (isMediumUp) {
    screenSize = "medium"
  }

  return (
    <Box
      component="img"
      src={`${STRAPI_URL}${imageFormatFinder(media, false, screenSize)}`}
      alt={media.alternativeText ?? ""}
      sx={{
        maxWidth: "calc(100vw - 100px)",
        maxHeight: "80vh",
        objectFit: "cover",
        borderRadius: 1,
      }}
    />
  )
}

const ThumbnailMedia = ({ media, idx }: { media: INewsImage; idx: number }) => {
  const isVideo = media.mime.startsWith("video/")

  if (isVideo) {
    return (
      <LazyLoadWrapper>
        <video
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={`${STRAPI_URL}${media.url}`} type={media.mime} />
        </video>
        <VideoOverlay>
          <PlayCircleOutlineIcon sx={{ fontSize: 40, color: "white" }} />
        </VideoOverlay>
      </LazyLoadWrapper>
    )
  }

  return (
    <LazyLoadWrapper>
      <ThumbnailImage
        src={`${STRAPI_URL}${imageFormatFinder(media, true)}`}
        alt={media.alternativeText ?? `Image ${idx + 1}`}
        loading="lazy"
      />
    </LazyLoadWrapper>
  )
}

export const NewsGallery: React.FC<NewsGalleryProps> = ({ media }: NewsGalleryProps) => {
  const [openModal, setOpenModal] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)

  if (media == null || media.length === 0) return null

  const openImageModal = (index: number) => {
    setCurrentIndex(index)
    setOpenModal(true)
  }

  const closeImageModal = () => {
    setOpenModal(false)
  }

  const prevImage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const nextImage = () => {
    if (currentIndex < media.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  return (
    <div className="pt-4">
      <Typography variant="h5" sx={{ pb: 1 }}>
        Gallery
      </Typography>

      {/* Thumbnail Grid */}
      <Grid container spacing={1}>
        {media.map((media, idx) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={idx}>
            <ThumbnailContainer
              onClick={() => {
                openImageModal(idx)
              }}
            >
              <ThumbnailMedia media={media} idx={idx} />
            </ThumbnailContainer>
          </Grid>
        ))}
      </Grid>

      {/* Fullscreen View as a 3x3 Grid */}
      <FullscreenModal open={openModal} onClose={closeImageModal}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto 1fr",
            gridTemplateRows: "1fr auto 1fr",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          {/* Top Row */}
          <Box sx={{ gridColumn: 1, gridRow: 1 }} />
          <Box sx={{ gridColumn: 2, gridRow: 1 }} />
          <Box
            sx={{
              gridColumn: 3,
              gridRow: 1,
              alignSelf: "start",
              justifySelf: "end",
            }}
          >
            <IconButton onClick={closeImageModal}>
              <CloseIcon sx={{ color: "white" }} />
            </IconButton>
          </Box>

          {/* Middle Row */}
          <Box
            sx={{
              gridColumn: 1,
              gridRow: 2,
              justifySelf: "start",
              alignSelf: "center",
              // marginLeft: 2,
            }}
          >
            {media.length > 1 && currentIndex > 0 && (
              <ModalNavButton onClick={prevImage} aria-label="Previous media">
                <ArrowBackIosIcon />
              </ModalNavButton>
            )}
          </Box>

          <Box
            sx={{
              gridColumn: 2,
              gridRow: 2,
              border: "4px solid white",
              borderRadius: "5px",
              justifySelf: "center",
              alignSelf: "center",
            }}
          >
            <MediaContainer media={media[currentIndex]} />
          </Box>

          <Box
            sx={{
              gridColumn: 3,
              gridRow: 2,
              justifySelf: "end",
              alignSelf: "center",
              // marginRight: 2,
            }}
          >
            {media.length > 1 && currentIndex < media.length - 1 && (
              <ModalNavButton onClick={nextImage} aria-label="Next media">
                <ArrowForwardIosIcon />
              </ModalNavButton>
            )}
          </Box>

          {/* Bottom Row */}
          <Box sx={{ gridColumn: 1, gridRow: 3 }} />
          <Box
            sx={{
              gridColumn: 2,
              gridRow: 3,
              justifySelf: "center",
              alignSelf: "end",
              marginBottom: 2,
            }}
          >
            {media.length > 1 && (
              <Typography variant="body1" sx={{ color: "white", fontWeight: 500 }}>
                {`${currentIndex + 1}/${media.length}`}
              </Typography>
            )}
          </Box>
          <Box sx={{ gridColumn: 3, gridRow: 3 }} />
        </Box>
      </FullscreenModal>
    </div>
  )
}
