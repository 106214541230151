import { createApi } from "@reduxjs/toolkit/query/react"
import baseQueryWithRejection from "../api/baseQueryWithRejection"
import type { IGetLatestResponse, NewsApiResponse } from "../../types/INews"

export const businessApi = createApi({
  reducerPath: "businessApi",
  baseQuery: baseQueryWithRejection,
  endpoints: (builder) => ({
    // -------------------------------
    // /api/Business
    // -------------------------------
    getBusinesses: builder.query({
      query: () => ({
        method: "POST",
        url: "/api/Business/GetAllBusiness",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getLocations: builder.query({
      query: () => ({
        url: "/api/Business/GetAllLocation",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getBusinessDetails: builder.query<any, string>({
      query: (businessId) => ({
        url: `/api/Business/GetBusinessDetails`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: { businessId },
      }),
      keepUnusedDataFor: 0,
    }),

    getPerformance: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetBusinessPerformanceReport",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getRevenue: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetBusinessRevenueReport",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getSales: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetBusinessSalesReport",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getCashAdjustments: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetCashAdjustments",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getCurrentCash: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetDeviceStatus",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getOverview: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetOverView",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getTerminals: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetTerminals",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    // -------------------------------
    // /api/Store
    // -------------------------------
    getPeriod: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Store/GetStoreSummary",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getTerminalDetails: builder.query<any, string>({
      query: (terminalId) => ({
        url: `/api/Store/GetTerminalDetails`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: { terminalId },
      }),
    }),

    // -------------------------------
    // /api/License
    // -------------------------------
    addItemToCart: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/AddItemToCart",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    createCart: builder.query({
      query: (body) => ({
        method: "GET",
        url: "/api/License/CreateCart",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
      keepUnusedDataFor: 0,
    }),

    createCheckout: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/CreateCheckout",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    createInvoice: builder.mutation({
      query: (params) => ({
        method: "GET",
        url: "/api/License/CreateInvoice",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        params,
      }),
    }),

    decodeRequestCode: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/DecodeRequestCode",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getFillHistoryByDateRange: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/GetFillHistoryByDateRange",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getFillHistoryByMachineId: builder.query<
      any,
      { machineId: string; numberOfRecords: number; currentPage: number }
    >({
      query: (body) => ({
        url: `/api/License/GetFillHistoryByMachineId`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
      keepUnusedDataFor: 0,
    }),

    getFillPricing: builder.query({
      query: () => ({
        method: "GET",
        url: "/api/License/GetFillPricing",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getMachines: builder.query({
      query: () => ({
        url: `/api/License/GetMachines`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getPaymentMethods: builder.query({
      query: (params) => ({
        method: "GET",
        url: "/api/License/GetPaymentMethods",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        params,
      }),
      keepUnusedDataFor: 0,
    }),

    getUserCartItems: builder.query({
      query: (params) => ({
        method: "GET",
        url: "/api/License/GetUserCartItems",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        params,
      }),
      keepUnusedDataFor: 0,
    }),

    getPaymentStatus: builder.query({
      query: (body) => ({
        method: "POST",
        url: "/api/License/PaymentStatus",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
      keepUnusedDataFor: 0,
    }),

    removeCartItem: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/RemoveCartItem",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    updateCartItem: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/UpdateCartItem",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    // -------------------------------
    // /api/news
    // -------------------------------
    getNews: builder.query<NewsApiResponse, null>({
      query: () => ({
        url: "/api/news/GetNews",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getNewArticles: builder.query({
      query: () => ({
        url: `/api/News/NewArticles`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: {
          email: localStorage.getItem("username"),
        },
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: IGetLatestResponse) => {
        localStorage.setItem("getLatest", JSON.stringify(response.ApiResponse))
        return response
      },
    }),

    updateLastRead: builder.mutation({
      query: () => ({
        url: `/api/News/UpdateLastRead`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: {
          email: localStorage.getItem("username"),
        },
      }),
    }),
  }),
})

export const {
  // -------------------------------
  // /api/Business
  // -------------------------------
  useGetBusinessesQuery,
  useGetLocationsQuery,
  useGetBusinessDetailsQuery,
  useGetPerformanceMutation,
  useGetRevenueMutation,
  useGetSalesMutation,
  useGetCashAdjustmentsMutation,
  useGetCurrentCashMutation,
  useGetOverviewMutation,
  useGetTerminalsMutation,

  // -------------------------------
  // /api/Store
  // -------------------------------
  useGetPeriodMutation,
  useGetTerminalDetailsQuery,

  // -------------------------------
  // /api/License
  // -------------------------------
  useAddItemToCartMutation,
  useCreateCartQuery,
  useCreateCheckoutMutation,
  useCreateInvoiceMutation,
  useDecodeRequestCodeMutation,
  useGetFillHistoryByDateRangeMutation,
  useGetFillHistoryByMachineIdQuery,
  useGetFillPricingQuery,
  useGetMachinesQuery,
  useGetPaymentMethodsQuery,
  useGetUserCartItemsQuery,
  useGetPaymentStatusQuery,
  useRemoveCartItemMutation,
  useUpdateCartItemMutation,

  // -------------------------------
  // /api/news
  // -------------------------------
  useGetNewsQuery,
  useGetNewArticlesQuery,
  useUpdateLastReadMutation,
} = businessApi
