import { useEffect, useState, useMemo } from "react"
import { AutoCompleteFullWidth } from "../../components/AutoCompleteFullWidth"
import { useGetBusinessesQuery, useGetTerminalsMutation } from "../../redux/business/business.api"
import { categories, contactMethod } from "../../data/supportpage"
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import { useGetProfileQuery, useSubmitSupportRequestMutation } from "../../redux/user/user.api"
import { toast } from "react-hot-toast"
import { SupportPageSkeleton } from "../../layouts/Skeletons/SupportPageSkeleton"
import { useSearchParams } from "react-router-dom"
// import { Button } from "antd"
import { EncryptStorage } from "encrypt-storage"

interface BusinessInfo {
  businessId: string
  name: string
  phone: string
  secondaryPhone: string
  street: string
  city: string
  zipPostal: string
  locality: string
  country: string
}

interface Business {
  businessInfo: BusinessInfo
  totalAlerts: number
}

const storage = new EncryptStorage("jfkejfklrjklgjerlk485859809gjtkljkgjl")

export function Support() {
  const { data, error, isLoading: isBusinessLoading } = useGetBusinessesQuery("")
  const { data: userInfo } = useGetProfileQuery("")
  const [fetchTerminals] = useGetTerminalsMutation()
  const [submitSupportRequest] = useSubmitSupportRequestMutation()
  const [business, setBusiness] = useState<string | null>(null)
  const [location, setLocation] = useState<string | null>(null)
  const [terminal, setTerminal] = useState<string | null>(null)
  const [category, setCategory] = useState<string | null>(null)
  const [problem, setProblem] = useState<string | null>(null)
  const [message, setMessage] = useState("")

  const [selectedBusiness, setSelectedBusiness] = useState<any>(null)
  const [selectedLocation, setSelectedLocation] = useState<any>(null)
  const [selectedTerminal, setSelectedTerminal] = useState<any>(null)
  const [selectedCategory, setSelectedCategory] = useState<any>(null)
  const [selectedProblem, setSelectedProblem] = useState<any>(null)

  const [allLocations, setAllLocations] = useState([])
  const [locations, setLocations] = useState([])
  const [terminals, setTerminals] = useState([])
  const [isLocationsLoading, setIsLocationsLoading] = useState(true)

  const [contactMethodSelected, setContactMethodSelected] = useState("")
  const [contactInfo, setContactInfo] = useState("")
  const [loading, setLoading] = useState(false)

  const [isTerminalsLoading, setIsTerminalsLoading] = useState(false)
  const [searchParams] = useSearchParams()

  // sorting business

  let sortedBusinesses: Business[] = []

  if (data?.data?.allBusiness !== null && data?.data?.allBusiness !== undefined) {
    sortedBusinesses = [...data?.data?.allBusiness]
    sortedBusinesses.sort((a: any, b: any) => {
      const nameA = a.businessInfo.name
      const nameB = b.businessInfo.name

      // Extract any leading number from the name
      const matchA = nameA.match(/^(\d+)?/)
      const matchB = nameB.match(/^(\d+)?/)

      // If both names have a leading number, compare those
      if (
        matchA?.[1] !== null &&
        matchA?.[1] !== undefined &&
        matchB?.[1] !== null &&
        matchB?.[1] !== undefined
      ) {
        const numA = parseInt(matchA[1], 10)
        const numB = parseInt(matchB[1], 10)

        return numA - numB
      }

      // If only one name has a leading number, that one comes first
      if (matchA?.[1] !== null && matchA?.[1] !== undefined) return -1
      if (matchB?.[1] !== null && matchB?.[1] !== undefined) return 1

      // If neither name has a leading number, compare the names alphabetically
      return nameA.localeCompare(nameB)
    })
  }

  // sorting terminals

  const sortedTerminals = useMemo(() => {
    const sortedTerminals: any = [...terminals]
    sortedTerminals.sort((a: any, b: any) => {
      const nameA = a.name
      const nameB = b.name

      // Extract any leading number from the name
      const matchA = nameA.match(/^(\d+)?/)
      const matchB = nameB.match(/^(\d+)?/)

      // If both names have a leading number, compare those
      if (
        matchA?.[1] !== null &&
        matchA?.[1] !== undefined &&
        matchB?.[1] !== null &&
        matchB?.[1] !== undefined
      ) {
        const numA = parseInt(matchA[1], 10)
        const numB = parseInt(matchB[1], 10)

        return numA - numB
      }

      // If only one name has a leading number, that one comes first
      if (matchA?.[1] !== null && matchA?.[1] !== undefined) return -1
      if (matchB?.[1] !== null && matchB?.[1] !== undefined) return 1

      // If neither name has a leading number, compare the names alphabetically
      return nameA.localeCompare(nameB)
    })

    return sortedTerminals
  }, [terminals])

  useEffect(() => {
    ;(async () => {
      setIsLocationsLoading(true)
      let locations = storage.getItem("locations")
      try {
        locations = locations.map((location: any) => {
          return {
            id: location.id,
            name: location.name,
            businessId: location.businessId,
          }
        })
        setLocations([])
        setAllLocations(locations)
      } finally {
        setIsLocationsLoading(false)
      }
    })().catch((error) => {
      console.log(error)
      setIsLocationsLoading(false)
    })
  }, [])

  useEffect(() => {
    const filteredLocations = allLocations.filter((location: any) => {
      return location.businessId === business
    })

    setLocations(filteredLocations)
  }, [business])

  useEffect(() => {
    if (location !== null && location !== undefined) {
      ;(async () => {
        setIsTerminalsLoading(true)
        const res: any = await fetchTerminals({ businessId: "", locationIds: [location] })
        const result = Object.values(res.data.data.terminals)[0]
        setTerminals(result !== null && result !== undefined ? result : ([] as any))
        if ("error" in res) {
          console.log(res.error)
        }
        setIsTerminalsLoading(false)
      })().catch((error) => {
        console.log(error)
        setIsTerminalsLoading(false)
      })
    }
  }, [location])

  // getting url params from 360 menu and saving states if option === true we don't want to select location

  useEffect(() => {
    const option = localStorage.getItem("option")

    if (
      !isBusinessLoading &&
      !isLocationsLoading &&
      data !== null &&
      data !== undefined &&
      allLocations.length > 0 &&
      searchParams.get("businessId") !== undefined &&
      searchParams.get("businessId") !== null &&
      searchParams.get("businessName") !== undefined &&
      searchParams.get("businessName") !== null
    ) {
      setBusiness(searchParams.get("businessId"))
      setSelectedBusiness({
        name: searchParams.get("businessName"),
        id: searchParams.get("businessId"),
      })
    }

    if (
      option !== "false" &&
      searchParams.get("locationId") !== undefined &&
      searchParams.get("locationId") !== null &&
      searchParams.get("locationName") !== undefined &&
      searchParams.get("locationName") !== null
    ) {
      setLocation(searchParams.get("locationId"))
      setSelectedLocation({
        name: searchParams.get("locationName"),
        id: searchParams.get("locationId"),
      })
    }

    if (searchParams.get("terminal") !== undefined && searchParams.get("terminal") !== null) {
      setTerminal(searchParams.get("terminal")) // Set the terminal state
      setSelectedTerminal({
        name: searchParams.get("terminal"),
        id: searchParams.get("terminal"),
      })
    }

    // Clear the option state from local storage,
    // for now I decided to go with the setTimeout function
    //  I don't like this approach but this is the only solution for now that got to my mind
    // Might be worth to rethink the logic a bit to avoid such a situation.
    // Ideally, we should aim to structure our code so that race conditions are not an issue, and the logic doesn't depend on timing.

    const timerId = setTimeout(() => {
      localStorage.removeItem("option")
    }, 1500)

    return () => {
      clearTimeout(timerId)
    }
  }, [data, allLocations, isBusinessLoading, isLocationsLoading])

  const handleBusinessChange = (e: React.SyntheticEvent<Element, Event>, value: any) => {
    setBusiness(value !== null ? value.toString() : null)
    setLocation(null) // Reset Location
    setTerminal(null) // Reset Terminal
    setSelectedLocation(null)
    setSelectedTerminal(null)
  }

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: any) => {
    setLocation(value !== null ? value.toString() : null)
    setTerminal(null)
    setSelectedTerminal(null)
  }

  const handleTerminalChange = (e: React.SyntheticEvent<Element, Event>, value: any) => {
    setTerminal(value !== null ? value.toString() : null)
  }

  const handleCategoryChange = (e: React.SyntheticEvent<Element, Event>, value: any) => {
    setCategory(value !== null ? value.toString() : null)
    setSelectedProblem(null)
    setProblem(null)
  }

  const handleProblemChange = (e: React.SyntheticEvent<Element, Event>, value: any) => {
    setProblem(value !== null ? value.toString() : null)
  }

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value)
  }

  const handleSetSelectedBusiness = (value: any | null) => {
    setSelectedBusiness(value)
  }

  const handleSetSelectedLocation = (value: any | null) => {
    setSelectedLocation(value)
  }

  const handleSetSelectedTerminal = (value: any | null) => {
    setSelectedTerminal(value)
  }

  const handleSetSelectedCategory = (value: any | null) => {
    setSelectedCategory(value)
  }

  const handleSetSelectedProblem = (value: any | null) => {
    setSelectedProblem(value)
  }

  const getContactInfo = (method: string) => {
    if (userInfo?.data?.userProfile !== undefined && userInfo?.data?.userProfile !== null) {
      switch (method) {
        case "Email":
          return userInfo.data.userProfile.email
        case "Phone":
          return userInfo.data.userProfile.phone
        case "Cell Phone":
          return userInfo.data.userProfile.cellphone
        default:
          return ""
      }
    }
    return ""
  }

  if (isBusinessLoading || isLocationsLoading) {
    return <SupportPageSkeleton />
  }

  if (error !== null && error !== undefined && !isBusinessLoading && !isLocationsLoading) {
    console.log(error)
  }

  const submitFormHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    // Check if required fields are empty
    if (business === null || location === null || selectedCategory === null) {
      toast.dismiss()
      toast.error("Please fill all the required fields.")
      setLoading(false)
      return
    }

    // If Category is "General Inquiry" or "Other", make sure the "Additional Details" is not empty
    if (
      (selectedCategory?.name === "General Inquiry" || selectedCategory?.name === "Other") &&
      message.trim() === ""
    ) {
      toast.dismiss()
      toast.error("Additional Details are required for General Inquiry or Other categories.")
      setLoading(false)
      return
    }

    if (!navigator.onLine) {
      toast.dismiss()
      toast.error("You are offline. Please check your internet connection and try again.")
      setLoading(false)
      return
    }

    try {
      const response = await submitSupportRequest({
        firstName: userInfo.data.userProfile.firstName,
        lastName: userInfo.data.userProfile.lastName,
        locationName: selectedLocation !== null ? selectedLocation.name : null,
        terminalName: selectedTerminal !== null ? selectedTerminal.name : null,
        responseType: contactMethodSelected,
        responseText: contactInfo,
        category: selectedCategory !== null ? selectedCategory.name : null,
        problem: selectedProblem !== null ? selectedProblem.name : null,
        details: message,
      })

      // Check for the specific response value you mentioned
      if ("data" in response && response.data.rc === 1000) {
        toast.dismiss()
        toast.success("Support request submitted successfully.")
        setSelectedLocation(null)
        setSelectedTerminal(null)
        setSelectedProblem(null)
        setSelectedCategory(null)
        setSelectedBusiness(null)
        setBusiness(null)
        setLocation(null) // Reset Location
        setTerminal(null) // Reset Terminal
        setSelectedLocation(null)
        setSelectedTerminal(null)
        setProblem(null)
        setMessage("")
        setContactMethodSelected("")
        setContactInfo("")
      } else {
        toast.error("Failed to submit support ticket. Please try again later.")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Container component="main" maxWidth="sm" className="mb-20">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" fontWeight="bold" textAlign="center">
            Support
          </Typography>
          <Typography variant="body1">
            Please fill out the form below to submit your support request. Note that fields marked
            with an asterisk (<span style={{ color: "red" }}>*</span>) are mandatory.
          </Typography>

          <br />
          <Typography>My issue is related to:</Typography>
          <br />
          <Box
            component="form"
            onSubmit={(event) => {
              event.preventDefault()
              submitFormHandler(event).catch((error) => {
                console.error("An error occurred", error)
              })
            }}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <Box gap={2} sx={{ display: "flex", flexDirection: "column" }}>
              <AutoCompleteFullWidth
                options={sortedBusinesses.map((business: any) => {
                  return { name: business.businessInfo.name, id: business.businessInfo.businessId }
                })}
                onChange={handleBusinessChange}
                label={
                  <span>
                    Choose Business <span style={{ color: "red" }}>*</span>
                  </span>
                }
                value={business}
                selectedValue={selectedBusiness}
                setSelectedValue={handleSetSelectedBusiness}
              />

              <AutoCompleteFullWidth
                options={locations}
                onChange={handleLocationChange}
                label={
                  <span>
                    Choose Location <span style={{ color: "red" }}>*</span>
                  </span>
                }
                value={location}
                selectedValue={selectedLocation}
                setSelectedValue={handleSetSelectedLocation}
                loading={isLocationsLoading}
                disabled={isLocationsLoading || business === null || business === ""}
              />

              <AutoCompleteFullWidth
                options={sortedTerminals.map((terminal: any) => {
                  return {
                    name: `${terminal.name as string} ${terminal.roleDisplayName as string}`,
                    id: terminal.terminalId,
                  }
                })}
                onChange={handleTerminalChange}
                label="Choose Terminal"
                value={terminal}
                selectedValue={selectedTerminal}
                setSelectedValue={handleSetSelectedTerminal}
                loading={isTerminalsLoading}
                disabled={isTerminalsLoading || location === null || location === ""}
              />
            </Box>
            <br />
            <Divider variant="middle" />
            <br />
            <Typography>To assist you better, please detail your issue:</Typography>
            <br />
            <Box gap={2} sx={{ display: "flex", flexDirection: "column" }}>
              <AutoCompleteFullWidth
                options={Object.keys(categories).map((category: string, index) => {
                  return { name: category, id: index }
                })}
                onChange={handleCategoryChange}
                label={
                  <span>
                    Choose Category <span style={{ color: "red" }}>*</span>
                  </span>
                }
                value={category}
                selectedValue={selectedCategory}
                setSelectedValue={handleSetSelectedCategory}
              />
              <AutoCompleteFullWidth
                options={
                  selectedCategory !== null &&
                  selectedCategory !== undefined &&
                  selectedCategory.name in categories
                    ? categories[selectedCategory.name].map((problem, index) => {
                        return { name: problem.label, id: index }
                      })
                    : []
                }
                onChange={handleProblemChange}
                label="Choose Problem"
                value={problem}
                selectedValue={selectedProblem}
                setSelectedValue={handleSetSelectedProblem}
                disabled={selectedCategory === null || selectedCategory === ""}
              />
              <TextField
                id="outlined-multiline-flexible"
                value={message}
                onChange={handleMessageChange}
                label={
                  <span>
                    Additional Details
                    {selectedCategory?.name === "General Inquiry" ||
                    selectedCategory?.name === "Other" ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : (
                      ""
                    )}
                  </span>
                }
                multiline
                rows={4}
                className="w-full"
              />

              <Typography>Preferred Response Method:</Typography>

              <TextField
                id="outlined-select-currency"
                select
                label="Contact"
                value={contactMethodSelected}
                defaultValue="Select"
                className="w-full"
                onChange={(event) => {
                  setContactMethodSelected(event.target.value)
                  setContactInfo(getContactInfo(event.target.value))
                }}
              >
                {contactMethod.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.value === "Select"}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-textarea"
                label="Choose Response Method"
                placeholder="Choose Response Method"
                value={contactInfo}
                disabled={contactMethodSelected === null || contactMethodSelected === ""}
                onChange={(event) => {
                  setContactInfo(event.target.value)
                }}
                className="w-full"
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{
                mt: 3,
                mb: 2,
                position: "relative",
              }}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}
