import React from "react"
import { BlocksRenderer, type BlocksContent } from "@strapi/blocks-react-renderer"
import { Box, Typography, List, Link } from "@mui/material"
// import Link from "react-router-dom" // or react-router-dom, etc.
import type { INewsBodyBlock } from "../../../types/INews"

// MUI-based styling constants
const fontFamily =
  '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

interface NewsBodyProps {
  body: INewsBodyBlock[] // This is the array of blocks from Strapi
}

// Optional: If your Strapi data format exactly matches the BlocksContent type
// you can cast your "body" as BlocksContent.
// Otherwise, define your own type or inline-cast as needed.

export const NewsBody: React.FC<NewsBodyProps> = ({ body }) => {
  return (
    <BlocksRenderer
      // The library expects an array of block objects (same shape as your 'body')
      content={body as unknown as BlocksContent}
      // OVERRIDE BLOCK RENDERING
      blocks={{
        paragraph: ({ children }) => (
          <Typography variant="body1" gutterBottom sx={{ fontFamily }}>
            {children}
          </Typography>
        ),

        heading: ({ children, level }) => {
          // Map 'level' (1..6) to Typography variant
          let variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" = "h6"
          switch (level) {
            case 1:
              variant = "h1"
              break
            case 2:
              variant = "h2"
              break
            case 3:
              variant = "h3"
              break
            case 4:
              variant = "h4"
              break
            case 5:
              variant = "h5"
              break
            case 6:
              variant = "h6"
              break
          }

          return (
            <Typography variant={variant} gutterBottom sx={{ fontFamily }}>
              {children}
            </Typography>
          )
        },

        quote: ({ children }) => (
          <Box
            sx={{
              borderLeft: "4px solid grey",
              pl: 2,
              py: 1,
              my: 2,
              bgcolor: "WhiteSmoke",
            }}
          >
            <Typography variant="body1" sx={{ fontStyle: "italic", fontFamily }}>
              {children}
            </Typography>
          </Box>
        ),

        code: ({ plainText }) => (
          <Box
            sx={{
              backgroundColor: "Gainsboro",
              borderRadius: 1,
              p: 2,
              my: 2,
              overflow: "auto",
            }}
          >
            <pre style={{ margin: 0 }}>
              <code>{plainText}</code>
            </pre>
          </Box>
        ),

        image: ({ image }) => {
          if ((image?.url).length === 0) return null
          return (
            <Box my={2}>
              <img
                src={image.url}
                alt={image.alternativeText ?? ""}
                style={{ maxWidth: "100%", height: "auto", borderRadius: "5px" }}
              />
            </Box>
          )
        },

        link: ({ children, url }) => {
          // Optionally use your favorite Link component from Next.js / React Router
          return (
            <Link href={url} style={{ color: "steelBlue", textDecoration: "none" }} target="_blank">
              {children}
            </Link>
          )
        },

        list: ({ children, format }) => {
          // format is usually 'ordered' or 'unordered'
          const isOrdered = format === "ordered"
          return (
            <Box sx={{ pl: 2, my: 1 }}>
              {isOrdered ? (
                <List component="ol" sx={{ listStyleType: "decimal" }}>
                  {children}
                </List>
              ) : (
                <List component="ul" sx={{ listStyleType: "disc" }}>
                  {children}
                </List>
              )}
            </Box>
          )
        },

        // If the library also has a separate 'list_item' block, you can override it:
        // list_item: ({ children }) => (
        //   <ListItem sx={{ display: 'list-item', p: 0, mb: 0.5 }}>
        //     <Typography variant="body1" sx={{ fontFamily }}>
        //       {children}
        //     </Typography>
        //   </ListItem>
        // ),
      }}
      // OVERRIDE INLINE MODIFIERS
      modifiers={{
        bold: ({ children }) => <strong>{children}</strong>,
        italic: ({ children }) => <em>{children}</em>,
        underline: ({ children }) => <u>{children}</u>,
        strikethrough: ({ children }) => <del>{children}</del>,
        code: ({ children }) => (
          <code style={{ backgroundColor: "#f0f0f0", padding: "2px 4px", borderRadius: "4px" }}>
            {children}
          </code>
        ),
      }}
    />
  )
}
