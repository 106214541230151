import { useState } from "react"
import type { CartItem } from "../types/cartItemTypes"
import {
  useRemoveCartItemMutation,
  useUpdateCartItemMutation,
} from "../redux/business/business.api"

interface MachineHook {
  cartItem: CartItem[]
  handleDeleteFill: (index: number) => Promise<void>
  addCartItem: (newCartItem: CartItem[], arg: boolean) => void
  fillActionsLoading: boolean
  setFillActionsLoading: React.Dispatch<React.SetStateAction<boolean>>
  handleDropdownChange: (index: number, selectedAmount: string, newPrice: number) => Promise<void>
}

function useFillActions(initialCartItem: CartItem[]): MachineHook {
  const [updateCartItem] = useUpdateCartItemMutation({})
  const [removeCartItem] = useRemoveCartItemMutation({})

  const [cartItem, setCartItem] = useState<CartItem[]>(initialCartItem)

  const [fillActionsLoading, setFillActionsLoading] = useState(false)

  const handleDeleteFill = async (index: number) => {
    setFillActionsLoading(true)
    const machineToRemove = cartItem[index]

    console.log("machineToRemove", machineToRemove)

    const removeData = {
      cartItemId: machineToRemove.cartItemId,
    }

    try {
      const res = await removeCartItem(removeData).unwrap()
      console.log("removeData", removeData)

      if (res?.rc === 1000) {
        setCartItem((currentMachine) => currentMachine.filter((_, i) => i !== index))
      } else {
        console.log("res", res)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setFillActionsLoading(false)
    }
  }

  const addCartItem = (newCartItems: CartItem[], replace = false) => {
    setCartItem((prevMachines) => {
      if (replace) {
        return [...newCartItems]
      } else {
        const uniquePrevMachines = prevMachines.filter(
          (prevItem) => !newCartItems.some((newItem) => newItem.cartItemId === prevItem.cartItemId)
        )

        return [...newCartItems, ...uniquePrevMachines]
      }
    })
  }

  const handleDropdownChange = async (index: number, selectedAmount: string, newPrice: number) => {
    setFillActionsLoading(true)
    const cartItemToUpdate = cartItem[index]

    const amountWithoutDollarSign = selectedAmount.replace("$", "")

    const updateData = {
      machineId: cartItemToUpdate.machineId,
      amount: amountWithoutDollarSign,
      price: newPrice,
      cartItemId: cartItemToUpdate.cartItemId,
    }

    try {
      const res = await updateCartItem(updateData).unwrap()
      if (res?.rc === 1000) {
        setCartItem((prevCartItems) => {
          const updatedCartItems = [...prevCartItems]

          console.log("updatedCartItems", ...prevCartItems)
          updatedCartItems[index] = {
            ...updatedCartItems[index],
            price: newPrice,
            amount: amountWithoutDollarSign,
          }
          return updatedCartItems
        })
      } else {
        console.log(res)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setFillActionsLoading(false)
    }
  }

  return {
    cartItem,
    handleDeleteFill,
    addCartItem,
    fillActionsLoading,
    setFillActionsLoading,
    handleDropdownChange,
  }
}

export default useFillActions
