import { Backdrop, CircularProgress } from "@mui/material"
import { PurchaseDetails } from "../../../components/Licensing/TransactionDetails/TransactionDetails"
import { useGetPaymentStatusQuery } from "../../../redux/business/business.api"
import type { MachineData } from "../../../types/paymentStatusTypes"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"

interface Payload {
  orderId?: string
  invoiceId?: string
}

export function PaymentStatus() {
  const orderId = localStorage.getItem("orderId")
  const invoiceId = localStorage.getItem("invoiceId")
  const idToUse = orderId ?? invoiceId
  const navigate = useNavigate()
  const payload: Payload = {}
  if (orderId !== null && orderId !== undefined) {
    payload.orderId = orderId
    payload.invoiceId = ""
  } else if (invoiceId !== null && invoiceId !== undefined) {
    payload.invoiceId = invoiceId
    payload.orderId = ""
  }

  const {
    data: paymentStatusData,
    isLoading,
    error,
  } = useGetPaymentStatusQuery(payload, {
    skip: idToUse === null || idToUse === undefined || idToUse === "",
  })

  useEffect(() => {
    if (!isLoading) {
      if (paymentStatusData === null || paymentStatusData === undefined) {
        console.log("no data to show in payment status")
        navigate("/licensing/purchase-fills")
      } else if (paymentStatusData.rc === 1000) {
        console.log("paymentSuccess")
      } else {
        console.log("Something went wrong.")
        navigate("/licensing/purchase-fills")
      }
    }
    return () => {
      localStorage.removeItem("orderId")
      localStorage.removeItem("invoiceId")
    }
  }, [paymentStatusData, isLoading, navigate])

  if (isLoading) {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  if (error !== null && error !== undefined && !isLoading) {
    return <div>Error retrieving payment data.</div>
  }

  console.log("paymentStatusData in PaymentStatus: ", paymentStatusData)
  const transaction = paymentStatusData?.data?.transaction?.[0]

  if (transaction === undefined) {
    if (paymentStatusData?.message === undefined) {
      toast.error("Something went wrong.")
    }
    navigate("/licensing")
  }

  const machineData = transaction?.machines?.map((machine: MachineData) => ({
    ...machine,
    filCount: machine.fillCount,
    fillPurchased: machine.fillPurchased,
    code: machine.fillCode,
    price: machine.price,
  }))

  return (
    <div className="w-full sm:max-w-4xl mx-auto mb-20">
      {transaction !== null && transaction !== undefined && (
        <PurchaseDetails
          transactionId={transaction.transactionId}
          purchaseDate={transaction.purchaseDate}
          purchaseTotal={transaction.purchaseTotal.toString()}
          machineData={machineData}
        />
      )}
    </div>
  )
}
