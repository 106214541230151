import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { type FilterNameType, type ActionType, type State } from "../types"

export const initialState: State = {
  transactionFilters: {
    Add: false,
    Stack: false,
    Remove: false,
  },
  productFilters: {},

  gameTerminalFilters: {
    PFH: false,
    POS: false,
    External_Jackpot: false,
    Kiosk: false,
  },
  statusFilters: {},
  newsTagFilters: {},
}

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    applyFilters: (state, action: ActionType) => {
      state[action.payload.filter][action.payload.filterType] = action.payload.value
    },
    clearFilters: (state, action: ActionType) => {
      const filterToClear = state[action.payload.filter]
      Object.keys(filterToClear).forEach((key) => {
        filterToClear[key] = false
      })
      state[action.payload.filter] = filterToClear
    },
    setProductFilters: (state, action) => {
      state.productFilters = action.payload
    },
    resetFilters: (state) => {
      Object.keys(initialState).forEach((filter) => {
        const filterName = filter as FilterNameType

        const filterToClear = state[filterName]
        Object.keys(filterToClear).forEach((key) => {
          filterToClear[key] = false
        })
        state[filterName] = filterToClear
      })
    },
    setStatusFilters: (state, action: PayloadAction<string[]>) => {
      const newStatusFilters = action.payload.reduce(
        (acc: Record<string, boolean>, status: string) => {
          acc[status] = !!state.statusFilters[status]
          return acc
        },
        {}
      )
      state.statusFilters = newStatusFilters
    },
  },
})

export const { applyFilters, clearFilters, setProductFilters, resetFilters, setStatusFilters } =
  filtersSlice.actions
export const filtersReducer = filtersSlice.reducer
