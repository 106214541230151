import { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import BottomNavigation from "@mui/material/BottomNavigation"
import BottomNavigationAction from "@mui/material/BottomNavigationAction"
import { homeButtons } from "../data/homepage"
import { useUserContext } from "../context/UserContext"
import { useNavigate, useLocation } from "react-router-dom"
import { Badge } from "@mui/material"

export function BottomNav() {
  const data = useUserContext()
  const navigate = useNavigate()
  const location = useLocation()

  const currentRouteIndex = homeButtons(data.signOutHandler)
    .slice(0, 5)
    .findIndex((value) => value.href === location.pathname)

  const [value, setValue] = useState(currentRouteIndex !== -1 ? currentRouteIndex : 0)

  const handleChange = (newValue: number) => {
    setValue(newValue)
    navigate(homeButtons(data.signOutHandler)[newValue].href ?? "/")
  }

  useEffect(() => {
    setValue(currentRouteIndex)
  }, [currentRouteIndex])

  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        boxShadow: "0px -2px 10px rgba(0,0,0,0.2)",
        zIndex: 50,
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_, newValue) => {
          handleChange(newValue)
        }}
        sx={{ backgroundColor: "#14213D" }}
      >
        {homeButtons(data.signOutHandler)
          .slice(0, 5)
          .map((value, index) => (
            <BottomNavigationAction
              key={value.href}
              value={index}
              onClick={() => {
                value.onclick != null ? value.onclick() : navigate(value.href ?? "/")
              }}
              label={value.title === "Account Settings" ? "Account" : value.title}
              style={{
                backgroundColor:
                  value.href != null &&
                  value.href !== "/" &&
                  location.pathname.startsWith(value.href)
                    ? "#FCA311"
                    : "transparent",
                color: "#ffffff",
              }}
              icon={
                <Badge
                  color="error"
                  variant="dot"
                  invisible={
                    !(
                      ((value.newArticle ?? false) &&
                        value.href != null &&
                        value.href !== "/" &&
                        !location.pathname.startsWith(value.href)) ??
                      false
                    )
                  }
                >
                  <value.Icon {...value.iconProps} />
                </Badge>
              }
            />
          ))}
      </BottomNavigation>
    </Box>
  )
}
